import React, { createContext, useContext, useState } from 'react';

// Context oluştur
const RequestHistoryContext = createContext();

// Provider oluştur
export const RequestHistoryProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <RequestHistoryContext.Provider value={{ isOpen, openModal, closeModal }}>
      {children}
    </RequestHistoryContext.Provider>
  );
};

// Context'i kullanmak için özel hook
export const useRequestHistory = () => useContext(RequestHistoryContext);
