import React, { useCallback, useEffect, useState } from "react";
import Header from "./Header";
import RequestHistoryButton from "./RequestHistoryButton";
import Tabs from "./Tabs";
import { getUserIdFromUrl } from "../helper/apiHelper";
import { CircularProgress } from "@mui/material";
import BonusCards from "./BonusCards";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import config from "../config";
import { useStyles } from "../context/StyleContext";
import '../css/HomePage.css'; // CSS dosyasını ekliyoruz

const HomePage = () => { 
    const [userName, setUserName] = useState('');
    const [selectedTab, setSelectedTab] = useState('Tümü');
    const [loading, setLoading] = useState(true);
    const userId = getUserIdFromUrl();
    const { executeRecaptcha } = useGoogleReCaptcha(); 
    const styles = useStyles();
  
    const fetchUserName = useCallback(async () => {
      let success = false;
      let attempts = 0; // Deneme sayısı
      const maxAttempts = 2; // Maksimum deneme sayısı
    
      while (!success && attempts < maxAttempts) {
        if (!executeRecaptcha) {
          attempts += 1; // Burada deneme sayısını artırıyoruz
          await new Promise(resolve => setTimeout(resolve, 10)); // 2 saniye bekle
          continue; // reCAPTCHA yoksa, döngüye geri dön
        }
    
        try {
          const token = await executeRecaptcha('forusername');   
          const response = await fetch(`${config.apiBaseUrl}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              process_id: 'user-name',
              user_id: userId,
              recaptcha: token,
            }),
          });
    
          const result = await response.json();
    
          if (result.status && result.full_name) {
            setUserName(result.full_name);
            success = true; // Başarı durumu
            setLoading(false); // Başarılı olduğunda loading false olur
          } else if (result.message === "Bilinmeyen kullanıcı") {
            setLoading(true);
            console.log(result.message);
            return;
          } else {
            setUserName('Kullanıcı');
            setLoading(true);
          }
        } catch (error) {
          console.error('İstek sırasında hata oluştu:', error);
          setUserName('Kullanıcı');
          setLoading(true);
        }
        attempts += 1; // Hata durumunda veya istek başarısızsa deneme sayısını artırıyoruz
      }
    }, [executeRecaptcha, userId]);
    
    useEffect(() => {
      fetchUserName();
    }, [fetchUserName]);
    
  
    
    const handleTabChange = (tab) => {
      setSelectedTab(tab);
    };
  
    if (loading || !userId) {
      return (
        <div className="loading-container" style={{ backgroundColor: styles.HomePage?.backgroundColor || "#282828ff" }}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <div className="homepage-container"  style={{ backgroundColor: styles.HomePage?.backgroundColor || "#282828ff" }}>
        <Header userName={userName} />

        <div className="tabs-request-container">
          <Tabs onTabChange={handleTabChange} selectedTab={selectedTab} />
          <RequestHistoryButton />
        </div>
        
        <div className="bonus-card-container-2" >
        <BonusCards selectedTab={selectedTab} />
        </div>
        

        <a 
          href="https://hookdijital.com/" 
          target="_blank" 
          rel="noopener noreferrer"
          className="hook-link"
        >
          <img src="./images/image7.png" alt="Hook logo" className="hook-logo" />
        </a>
      </div>
    );
};

export default HomePage;
