import React from 'react';
import { useStyles } from '../context/StyleContext'; // Context'ten stilleri alıyoruz
import '../css/TabItem.css';

const TabItem = ({ content, isActive, onClick }) => {
  const styles = useStyles();

  // Varsayılan renkler
  const defaultColor = "#e1e1e1";
  const defaultActiveColor = "#e5b645";
  const defaultActiveBorderColor = "#e5b645";

  return (
    <div
      className={`tab-item ${isActive ? 'active' : ''}`}
      onClick={onClick}
      style={{
        color: isActive
          ? styles.NavBar?.TabMenuItem?.activeColor || defaultActiveColor
          : styles.NavBar?.TabMenuItem?.color || defaultColor,
        borderBottom: isActive
          ? `2px solid ${styles.NavBar?.TabMenuItem?.activeBorderColor || defaultActiveBorderColor}`
          : 'none',
      }}
    >
      {content}
    </div>
  );
};

export default TabItem;
