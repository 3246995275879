import React, { useEffect, useState } from 'react';
import RequestHistory from './RequestHistoryDialog';
import '../css/historyButton.css';
import { useRequestHistory } from '../context/RequestHistoryContext';
import { useStyles } from '../context/StyleContext';
import RestoreIcon from '@mui/icons-material/Restore';

const RequestHistoryButton = () => {
  const { isOpen, openModal, closeModal } = useRequestHistory();
  const styles = useStyles();
  const [iconSize, setIconSize] = useState(30); // Varsayılan PC boyutu

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIconSize(22); // Mobil boyutu
      } else {
        setIconSize(30); // PC boyutu
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Sayfa yüklendiğinde boyutu kontrol et

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <>
      <div
        onClick={openModal}
        className="request-history-button"
        style={{ backgroundColor: styles.HistoryButtonContainer.HistoryButton?.backgroundColor || "#e5b645ff" }}
      >
        <div
          className="icon-container"
          style={{ background: styles.HistoryButtonContainer.HistoryButtonIconContainer?.backgroundColor || "#1D1D1D" }}
        >
          <RestoreIcon
            style={{
              color: styles.HistoryButtonContainer.HistoryButtonIcon?.IconColor || "#e5b645ff",
              fontSize: iconSize, // Dinamik font boyutu
            }}
          />
        </div>
        <span
          className="button-text"
          style={{ color: styles.HistoryButtonContainer.HistoryButton?.TextColor || "#1d1d1dff" }}
        >
          Talep Geçmişi
        </span>
      </div>

      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <RequestHistory closeModal={closeModal} />
          </div>
        </div>
      )}
    </>
  );
};

export default RequestHistoryButton;
