import React, { useState, useEffect } from 'react';
import DoubleBonus from './DoubleBonus';
import Rules from './RulesDialog';
import '../css/BonusCards.css';
import { useSnackbarWithApiRequest } from '../helper/apiHelper';
import { useBonus } from '../context/BonusContext';
import { useStyles } from '../context/StyleContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const BonusCards = ({ selectedTab }) => {
  const { bonusData, loading, error } = useBonus();
  const [isOpen, setIsOpen] = useState(false); // DoubleBonus için modal state
  const [isRulesOpen, setIsRulesOpen] = useState(false); // RulesDialog için modal state
  const [hoveredCard, setHoveredCard] = useState(null);
  const [currentBonus, setCurrentBonus] = useState(null);
  const styles = useStyles();
  const [iconSize, setIconSize] = useState(30); // Varsayılan PC boyutu

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIconSize(25); // Mobil boyutu
      } else {
        setIconSize(35); // PC boyutu
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Sayfa yüklendiğinde boyutu kontrol et

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // useSnackbarWithApiRequest hook'unu kullan
  const { SnackbarAlert, sendBonusRequest } = useSnackbarWithApiRequest();

  // useEffect her zaman çağrılır, modal açık olduğunda body scroll'unu kapatır
  useEffect(() => {
    if (isOpen || isRulesOpen) {
      document.body.style.overflow = 'hidden'; // Modal açıldığında scroll'u kapat
    } else {
      document.body.style.overflow = 'auto'; // Modal kapandığında scroll'u geri getir
    }

    // Cleanup function: Modal kapandığında scroll'u geri aç
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen, isRulesOpen]); // isOpen ve isRulesOpen her değiştiğinde çalışacak

  if (loading) return <p>Yükleniyor...</p>;
  if (error) return <p>Hata: {error}</p>;

  const openModal = () => setIsOpen(true);
  const openRulesModal = () => setIsRulesOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setIsRulesOpen(false);
  };

  // Talep Et butonuna tıklandığında çalışacak fonksiyon
  const handleTalepEt = (bonus) => {
    if (bonus.data.length > 1) {
      setCurrentBonus(bonus);
      openModal();
    } else {
      sendBonusRequest(bonus.data[0].bonus_id); // Tek bonus, direkt API isteği
    }
  };

  // Kurallar butonuna tıklandığında çalışacak fonksiyon
  const handleKurallar = (bonus) => {
    setCurrentBonus(bonus);
    openRulesModal();
  };

  // Bonusları filtreleme
  const filteredBonuses = selectedTab === 'Tümü'
  ? bonusData
  : bonusData.filter(bonus => 
      bonus.bonus_type
        .split(', ') 
        .map(type => type.toLowerCase())
        .includes(selectedTab.toLowerCase())
    );


  return (
    <div className="bonus-cards-container">
      {filteredBonuses.map((data, index) => (
        <div
          key={index}
          className="bonus-card"
          onMouseEnter={() => setHoveredCard(index)}
          onMouseLeave={() => setHoveredCard(null)}
        >
          <img
            src={data.bonus_gorsel}
            className={`bonus-image ${hoveredCard === index ? 'hovered' : ''}`}
            alt="Bonus Görsel"
          />
          
          <div className="info-icon" onClick={() => handleKurallar(data)}>
            <InfoOutlinedIcon style={{
              fontSize: iconSize,
              color: styles.BonusCard.InformationIcon?.IconColor || "#e5b645ff"
            }}/>
          </div>

          {hoveredCard === index && (
            <div className="button-group" >
              <button
                onClick={() => handleTalepEt(data)}
                className="btn talep-btn"
                style={{ backgroundColor: styles.BonusCard.RequestButton?.backgroundColor || "#e5b645", color: styles.BonusCard.RequestButton?.TextColor || "#1d1d1d" }}
              >
                Talep Et
              </button>
              <button
                onClick={() => handleKurallar(data)}
                className="btn kurallar-btn"
                style={{ backgroundColor: styles.BonusCard.RulesButton?.backgroundColor || "#1d1d1d", color: styles.BonusCard.RulesButton?.TextColor || "#fff" }}
              >
                Kurallar
              </button>
            </div>
          )}
        </div>
      ))}

{isOpen && currentBonus && (
  <div className="modal-overlay-doublebonus" onClick={closeModal}>
    <div className="modal-content-doublebonus" onClick={(e) => e.stopPropagation()}>
      <DoubleBonus closeModal={closeModal} bonusData={currentBonus.data} />
    </div>
  </div>
)}


{isRulesOpen && currentBonus && (
  <div className="modal-overlay-doublebonus" onClick={closeModal}>
    <div className="modal-content-doublebonus" onClick={(e) => e.stopPropagation()}>
      <Rules closeModal={closeModal} bonusData={currentBonus.data} />
    </div>
  </div>
)}


      {/* API'den gelen tüm Snackbar'ları burada göster */}
      {SnackbarAlert}
    </div>
  );
};

export default BonusCards;
