import React, { useState } from 'react';
import '../css/RulesDialog.css';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from '../context/StyleContext';

export default function Rules({ closeModal, bonusData }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const styles = useStyles();

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="rulesMain" style={{ background: styles.RulesDialogStyles?.Dialog?.backgroundColor || "#1e1e1e" }}>
      <div className="RulesHeader" style={{ color: styles.RulesDialogStyles?.DialogHeader?.TextColor || "white" }}>
        <span>Bonus Kuralları</span>
        <CloseIcon
          
          onClick={closeModal} 
          style={{ cursor: 'pointer' }}
          className='rulesCloseIcon' // Beyaz renk ayarladık
        />
      </div>

      <div className="rules-content">
        {/* Bonus başlıkları ve kurallarını dinamik olarak göster */}
        {bonusData.map((bonus, index) => (
          <div className="accordion" key={index}>
            <div className="accordion-item">
              <div className="accordion-title" onClick={() => toggleAccordion(index)} style={{ color: styles.RulesDialogStyles?.AccordionTittle?.TextColor || "#e5b645" }}>
                {bonus.bonus_name} Kuralları
              </div>
              {activeIndex === index && (
                <div className="accordion-content">
                  {bonus.kurallar.map((kural, i) => (
                    <div key={i}>
                      <strong style={{
                        color: styles.RulesDialogStyles?.AccordionContentTittle?.TextColor || "#e5b645"
                      }}>{kural.title}</strong>
                      <p style={{ color: styles.RulesDialogStyles?.AccordionContentText?.TextColor || "white" }} >{kural.metin}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
