import React, { createContext, useContext, useEffect, useState } from "react";
import config from "../config";

const StyleContext = createContext();

export const StyleProvider = ({ children }) => {
  const [styles, setStyles] = useState({});

  useEffect(() => {
    const fetchStyles = async () => {
      try {
        const response = await fetch(`${config.cssJsonUrl}`);
        const data = await response.json();
        setStyles(data.styles);
      } catch (error) {
        console.error("Error fetching styles:", error);
      }
    };

    fetchStyles();
  }, []);

  return (
    <StyleContext.Provider value={styles}>
      {children}
    </StyleContext.Provider>
  );
};

export const useStyles = () => useContext(StyleContext);
