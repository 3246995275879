import React from 'react';
import '../css/Header.css';
import { useStyles } from '../context/StyleContext';


const Header = ({userName}) => {
const styles = useStyles();
  return (
    <div className="header-container">
      <h1 className="header-title" style={{ color: styles.HeaderContainer.Header?.TextColor || "#FFFFFF" }}>
        <span className="greeting-emoji">👋🏻</span> Hoşgeldin, <span className="user-name" style={{ marginLeft: 10 }}>
          {userName || 'Kullanıcı'} {/* Kullanıcı adı yoksa 'Kullanıcı' gösterilir */}
        </span>
      </h1>
      <p className="header-subtitle" style={{ color: styles.HeaderContainer.HeaderSubTittle?.TextColor || "#ffffff9c" }}>
        Lütfen talep ettiğiniz bonusu seçiniz.
      </p>
    </div>
  );
};

export default Header;
